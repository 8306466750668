<template>
  <div class="pt-3">
    <v-alert
      v-if="alertMessage"
      dense
      dismissible
      type="warning"
      class="alert"
    >
      {{ alertMessage }}
    </v-alert>
    <v-row class="pb-3">
      <v-col
        cols="12"
      >
         <v-toolbar
          flat
          class="mb-1"
          :prominent="!quickLinkItems"
        >
          <div class="toolbar">
            <v-combobox
              :items="quickLinks"
              @change="onQuickLink()"
              label="Actions rapides"
              v-model="quickLink"
              dense
              item-color="#20A3B1"
              color="#20A3B1"
              class="quickLink"
            ></v-combobox>
            <v-text-field
              v-if="!quickLinkItems"
              v-model="search"
              clearable
              flat
              hide-details
              prepend-inner-icon="mdi-magnify"
              append-icon="mdi-send"
              @click:clear="onClear"
              @click:append="onSearch"
              @keyup.enter="onSearch"
              label="Saisissez la ou les adresses emails"
            ></v-text-field>
          </div>
         </v-toolbar>
      </v-col>
    </v-row>
    <v-data-iterator
      v-if="items && items.length > 0"
      item-key="id_benef"
      :items="items"
      :page.sync="page"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="(item, key) in props.items"
            :key="key"
            :id="key"
            cols="12"
            sm="12"
            md="12"
            lg="12"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold" style="padding-left: 5px;">
                <v-btn @click="onExpand(item)" icon style="margin-left: 5px;">
                  <v-icon v-if="!item.isExpanded" style="font-size: 14px;">
                    mdi-chevron-down
                  </v-icon>
                  <v-icon v-else style="font-size: 14px;">
                    mdi-chevron-up
                  </v-icon>
                </v-btn>
                <template v-if="!item.onEditEmail" >
                  <span class="title">{{ item.email }}</span>
                  <v-btn v-if="item.infocep && item.infocep.role" @click="onEditEmail(item)" icon style="margin-left: 5px;">
                    <v-icon style="font-size: 14px;">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <template v-else>
                  <v-text-field
                    v-model="item.email"
                    flat
                    hide-details
                    append-icon="mdi-send"
                    clearable
                    @click:clear="onClearUpdateUser(item)"
                    @click:append="onUpdateUser(item)"
                    @keyup.enter="onUpdateUser(item)"
                  ></v-text-field>
                </template>
              </v-card-title>
              <v-card-subtitle class="subtitle">
                <v-tooltip v-if="!item.onUpdateConseiller"  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="onExpand(item)"
                      icon
                      :color="setSubtitle(item).color"
                      style="margin-left: 5px;"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>
                        {{ setSubtitle(item).icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ setSubtitle(item).info }}</span>
                </v-tooltip>
                {{ setSubtitle(item).text }}
                <!-- <div v-if="item.infocep && item.infocep.id && [1, 2].indexOf(item.infocep.id) > -1" class="cta-delete d-flex justify-center align-center">
                  <v-btn
                    small
                    @click="onDeleteUser(item)"
                    color="red"
                    style="margin-left: 12px;"
                  >
                    supprimer cet utilisateur
                  </v-btn>
                </div> -->
              </v-card-subtitle>

              <v-divider></v-divider>

              <v-list :key="`list_${key}`" v-if="item.isExpanded" dense>
                <v-card-text :style="[item.date_del ? { 'textAlign': 'center' } : { 'textAlign': 'left' }]">
                  <template v-if="!item.isExists">
                    <v-alert
                      outlined
                      type="error"
                      prominent
                      border="left">
                      Cet utilisateur n'existe pas dans SIE.
                    </v-alert>
                  </template>
                  <template v-if="item.date_del">
                    <v-alert
                      outlined
                      type="error"
                      prominent
                      border="left">
                      Cet utilisateur a été supprimé de SIE le {{ item.date_del | formatDate }}.
                    </v-alert>
                    <v-btn
                      v-if="item.infocep && item.infocep.role"
                      @click="onDelete(item)"
                      style="margin-top: 10px; width: 250px;"
                      color="#000091"
                      class="bg-cep-blue"
                      small
                    >
                      <template v-if="!item.onDelete">
                        supprimer du site Infocep.fr
                      </template>
                      <template v-else>
                      <v-progress-circular
                          :size="20"
                          :width="3"
                          color="white"
                          indeterminate
                          style="margin: 0 auto; width: 100%;"
                        />
                      </template>
                    </v-btn>
                  </template>
                  <template v-if="item.isExists && !item.date_del">
                    <div class="font-weight-bold ml-8 mb-2">
                      Parcours d'inscription
                    </div>
                    <v-timeline
                      align-top
                      dense
                    >
                      <v-timeline-item
                        v-for="(key, index) in filteredKeys"
                        :key="index"
                        :color="key | timelineColor(item)"
                        small
                      >
                        <div class="font-weight-normal">
                          <strong>{{ key }}</strong>
                        </div>
                        <div v-if="key === 'Datamart'">
                          <div style="color: grey; font-size:12px;">Inscrit le : {{ item.date_crea | formatDate }}</div>
                          <div v-if="item.isConseiller || item.isCDI" style="color: grey; font-size:12px;">Date entrée : {{ item.date_entree | formatDate('date') }}</div>
                          <div v-if="item.isConseiller || item.isCDI" style="color: grey; font-size:12px;">Date sortie : {{ item.date_sortie | formatDate('date') }}</div>
                          <div v-if="item.isConseiller || item.isCDI" style="color: grey; font-size:12px;">Droit : {{ item | displayRole }}</div>
                          <div style="color: grey; font-size:12px;">Région : {{ item.region }}</div>
                          <div v-if="!item.isConseiller && !item.isCDI" style="color: grey; font-size:12px;">UO : {{ item.uo }}</div>
                          <div v-if="!item.isConseiller && !item.isCDI" style="color: grey; font-size:12px;">Conseiller : {{ item.conseiller }}</div>
                          <div style="color: grey; font-size:12px;">Dernière MàJ : {{ item.date_update | formatDate }}</div>
                          <div v-if="!item.isConseiller && !item.isCDI" style="display: flex; justify-content: left; align-items: center;font-size:12px;">
                            <strong>Critères :</strong>
                            <v-icon :color="getCriteresStatus(item).icon.color" style="font-size:16px; padding-left: 5px; padding-right: 5px;">{{ getCriteresStatus(item).icon.icon }}</v-icon>
                            <a @click="onDisplayCriteria()">
                              <span v-if="!isDisplayCriteria">voir les détails</span>
                              <span v-else>masquer les détails</span>
                            </a>
                          </div>
                          <div v-if="!item.isConseiller && !item.isCDI" style="display: flex; justify-content: left; align-items: center;font-size:12px;">
                            <table v-if="isDisplayCriteria" style="width: 80%;">
                              <tr>
                                <th>Rendez-vous</th>
                                <th>Date de finalisation</th>
                                <th>Statut</th>
                              </tr>
                              <tr>
                                <td>{{ item.rdv_uo }}</td>
                                <td>{{ item.date_finalisation| formatDate('date') }}</td>
                                <td v-html="getCriteresStatus(item).message"></td>
                              </tr>
                              <tfoot>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <v-btn
                                      @click="onDisplayParcours()"
                                      style="margin-top: 10px;"
                                      color="#000091"
                                      class="bg-cep-blue"
                                      small
                                    >
                                      <span v-if="!isDisplayParcours">voir le détail du parcours du bénéficiaire</span>
                                      <span v-else>masquer le détail du parcours du bénéficiaire</span>
                                    </v-btn>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <div class="details-parcours" v-if="isDisplayParcours && item.parcours">
                            <!-- <p style="padding-top: 10px; margin-bottom: 5px;"><strong>Parcours :</strong></p> -->
                            <table style="width:100%; padding-top: 10px;">
                              <thead>
                                <tr>
                                  <th colspan="7" style="text-align: center;">
                                    PARCOURS
                                  </th>
                                </tr>
                              </thead>
                              <tr>
                                <th>Date engagement</th>
                                <th>Date finalisation</th>
                                <th>Date del</th>
                                <th>UO</th>
                                <th>Statut</th>
                                <th>Dépot</th>
                                <th>Motif statut</th>
                              </tr>
                              <tr v-for="parcours in item.parcours" :key="parcours.id_parcours">
                                <td>{{ parcours.date_engagement | formatDate('date') }}</td>
                                <td>{{ parcours.date_finalisation| formatDate('date') }}</td>
                                <td>
                                    <span style="color: #FF0000;" v-if="parcours.date_del">
                                      {{ parcours.date_del | formatDate('date') }}
                                    </span>
                                </td>
                                <td>{{ parcours.UO }}</td>
                                <td>{{ parcours.statut_dossier }}</td>
                                <td>{{ parcours.depot_dossier }}</td>
                                <td>{{ parcours.motif_cloture }}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div v-if="key === 'Smart Profile'">
                          <template v-if="item.smartp.length > 0">
                            <div style="color: grey; font-size:12px;">Onboarding</div>
                            <div style="color: grey; font-size:12px;">Envoyé le : {{ item.smartp[0].sent | formatDate }}</div>
                            <div v-if="item.smartp[0].hardbounce.length === 0" style="color: grey; font-size:12px;">
                              Ouvert le : {{ item.smartp[0].open | formatDate }}
                            </div>
                            <div v-else style="color: #E1000F; font-size:12px;">
                              <div style="display: flex; align-items: center;">
                                <v-icon color="red">
                                  mdi-alert-outline
                                </v-icon>
                                <span style="padding-left: 5px;">NPAI Hard</span>
                              </div>
                              <p>Merci de contacter Smart Profile avant de renvoyer l'invitation</p>
                            </div>
                            <template v-if="(!item.smartp[0].open && !item.infocep.role) || (dateDiff(item.smartp[0].open) >= 0 && !item.infocep.role)">
                              <v-btn
                                v-if="!item.onboardingSent"
                                @click="onSendOnboarding(item)"
                                style="margin-top: 10px; width: 250px;"
                                color="#000091"
                                class="bg-cep-blue"
                                small
                              >
                                <template v-if="!item.onSend">
                                  renvoyer l'invitation
                                </template>
                                <template v-else>
                                <v-progress-circular
                                    :size="20"
                                    :width="3"
                                    color="white"
                                    indeterminate
                                    style="margin: 0 auto; width: 100%;"
                                  />
                                </template>                              
                              </v-btn>
                              <v-alert
                                  v-if="item.onboardingSent"
                                  outlined
                                  type="success"
                                  small>
                                  Message envoyé
                              </v-alert>
                            </template>
                          </template>
                          <template v-else>
                            <div style="color: grey; font-size:12px;">Onboarding non envoyé</div>
                            <v-btn
                              v-if="!item.onboardingSent"
                              @click="onSendOnboarding(item)"
                              style="margin-top: 10px;"
                              color="#000091"
                              class="bg-cep-blue"
                              small
                            >
                              envoyer l'invitation
                            </v-btn>
                            <v-alert
                              v-if="item.onboardingSent"
                              outlined
                              type="success"
                              small>
                              Message envoyé
                            </v-alert>
                          </template>
                        </div>
                        <div v-if="key === 'Infocep.fr'">
                          <template  v-if="item.infocep.role">
                            <div style="color: grey; font-size:12px;">{{ item.infocep.username }}</div>
                            <div style="color: grey; font-size:12px;">Inscrit le : {{ item.infocep.createdAt | formatDate }}</div>
                            <div style="color: grey; font-size:12px;">
                              Droit : {{ item.infocep.role.id | idRoleToString }}
                              <v-tooltip v-if="!item.onUpdateUser && !hasEqualRight(item)" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    @click="onUpdateUser(item, 'role')"
                                    icon
                                    color="#E1000F"
                                    style="margin-left: 5px;"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon style="font-size: 14px;">
                                      mdi-sync-alert
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Mettre à jour les droits
                                </span>
                              </v-tooltip>

                               <v-progress-circular
                                v-if="item.onUpdateUser && !item.onUpdatedUser"
                                indeterminate
                                :size="15"
                                :width="2"
                                style="padding-left: 50px;"
                                color="#000091"
                              ></v-progress-circular>
                              <v-icon v-if="item.onUpdatedUser" color="green" style="font-size:16px; padding-left: 25px;">mdi-check-circle</v-icon>


                            </div>
                            <div v-if="!item.isConseiller && !item.isCDI" style="color: grey; font-size:12px;">UO : {{ item.infocep.UO }}</div>
                            <div style="color: grey; font-size:12px;">Messagerie : 
                              <span v-if="!item.isConseiller && !item.isCDI">{{ (item.infocep.hasMessages) ? 'oui' : 'non' }}</span>
                              <span v-else>{{ item.infocep.messages_threads.length }} relation(s)</span>
                            </div>
                            <div v-if="!item.isConseiller && !item.isCDI" style="display: flex; align-items: center; color: grey; font-size:12px;">Conseiller :
                              <span v-if="item.infocep.conseiller">{{ item.infocep.conseiller.username }}</span>
                              <span v-if="!item.infocep.conseiller">
                                <span v-if="parseInt(item.infocep.code_fc_conseiller, 10) === item.id_conseiller"><em>non inscrit sur Infocep.fr</em></span>
                                <span v-else>-</span>
                              </span>
                              
                              <v-tooltip v-if="!item.onUpdateConseiller"  bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="(parseInt(item.infocep.code_fc_conseiller, 10) === 0 && item.infocep.conseiller) || (parseInt(item.infocep.code_fc_conseiller, 10) !== item.id_conseiller)"
                                    @click="onUpdateConseiller(item)"
                                    icon
                                    color="#E1000F"
                                    style="margin-left: 5px;"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon style="font-size: 14px;">
                                      mdi-sync-alert
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span v-if="(item.id_conseiller === 0 && item.infocep.conseiller)">
                                  Dissocier le conseiller
                                </span>
                                <span v-if="!item.infocep.conseiller">
                                  Associer le conseiller {{ item.conseiller }}
                                </span>
                                <span v-if="item.infocep.conseiller && item.id_conseiller !== 0  && parseInt(item.infocep.code_fc_conseiller, 10) !== item.id_conseiller">
                                  Changer le conseiller {{ item.infocep.conseiller.username }} par {{ item.conseiller }}
                                  <span v-if="item.infocep.hasMessages"> et transférer la messagerie.</span>
                                </span>
                              </v-tooltip>
                              <v-progress-circular
                                v-if="item.onUpdateConseiller && !item.onUpdatedConseiller"
                                indeterminate
                                :size="15"
                                :width="2"
                                style="padding-left: 50px;"
                                color="#000091"
                              ></v-progress-circular>
                              <v-icon v-if="item.onUpdatedConseiller" color="green" style="font-size:16px; padding-left: 25px;">mdi-check-circle</v-icon>
                            </div>

                            <div v-if="item.isConseiller" style="color: grey; font-size:12px;">Bénéficiaires : {{ item.infocep.numBenef }}</div>
                            <div style="color: grey; font-size:12px;">Dernière MàJ : {{ item.infocep.updatedAt | formatDate }}</div>
                          </template>
                          <template v-else>
                            <div style="color: grey; font-size:12px;">Utilisateur non-inscrit</div>
                          </template>
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                    <!-- <div v-if="!item.isConseiller && !item.isCDI" class="cta-delete d-flex justify-center align-center">
                      <v-btn
                        @click="onDeleteUser(item)"
                        color="red"
                      >
                        supprimer cet utilisateur
                      </v-btn>
                    </div> -->
                  </template>
                 </v-card-text>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-card v-if="quickLinkItems && quickLinkItems.length > 0">
      <v-card-title>
        <v-text-field
          v-model="quickLinkSearch"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        item-key="id"
        :headers="quickLinkItemsHeaders"
        :items="quickLinkItems"
        :search="quickLinkSearch"
        :items-per-page="quickLinkItemsPerPage"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              :loading="syncLoading"
              @click="onUpdateConseillerByBatch()"
              class="bg-cep-blue ma-2 pa-3"
              color="#000091"
              small>
              Synchroniser les comptes
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.conseiller="{ item }">
          {{ item.conseiller.username }} ({{ item.conseiller.id }})
        </template>
        <template v-slot:item.state="{ item }">
          <v-icon v-if="!item.state" color="red">
            mdi-alert-outline
          </v-icon>
          <v-icon v-else color="green">
            mdi-check-circle-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-row
      v-if="loading"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col
        class="subtitle-1 text-center"
        cols="12"
      >
        Recherche des bénéficiaires
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="#000091"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <div v-if="notFound">
      {{ notFound }}
    </div>

    <v-dialog
      v-model="confirmDeleteUser"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Confirmation de suppression</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                style="text-align: left;"
              >
              Pour confirmer la suppression de cet utilisateur, merci de saisir son adresse email ci-dessous :
                <v-text-field
                  v-model="confirmUserEmail"
                  label="Email"
                  required
                ></v-text-field>
                <span v-if="confirmUserEmailError" style="color: red">L'adresse email saisie ne correspond à celle de l'utilisateur</span>
                <span v-if="deleteUserSieError" style="color: red">Ce compte ne peut être supprimé car actif sur SIE</span>
                <template v-if="deletedUserDataList.length > 0">
                  <div style="text-align: center;">
                    Suppressions des données
                  </div>
                  <v-list flat>
                    <v-list-item
                      v-for="(item, i) in deletedUserDataList"
                      :key="`delete_user_data_${i}`"
                      class="delete-user-data">
                      <v-list-item-icon>
                        <v-icon color="green">mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div v-if="userIsDeleted" style="text-align: center; padding-top: 4px;">
                    Les données du compte sont supprimées
                    <p><a @click="onCloseDeleteUserModal()">Fermer</a></p>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="deletedUserDataList.length === 0">
          <v-spacer></v-spacer>
          <v-btn
            color="dark darken-1"
            text
            @click="confirmDeleteUser = false"
          >
            annuler
          </v-btn>
          <v-btn
            @click="onConfirmDeleteUser()"
            color="red darken-1"
            text
          >
            supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import eachOfSeries from 'async/eachOfSeries'
  import * as dayjs from 'dayjs'
  import _ from 'lodash'
  export default {
    name: 'Users',
    data () {
      return {
        search : '',
        loading: false,
        alertMessage: null,
        filter: {},
        sortDesc: false,
        page: 1,
        sortBy: 'email',
        keys: [
          'Email',
          'Datamart',
          'Smart Profile',
          'Infocep.fr'
        ],
        notFound: false,
        items: [],
        item: null,
        lastExpandedItem: null,
        quickLink: null,
        quickLinks: [
          {
            text: 'vérifier la syncho conseillers/bénéficiaire',
            value: 'sync',
          },
          {
            text: 'vérifier les UOs',
            value: 'uo',
          },
          {
            text: 'vérifier le statut des utilisateurs',
            value: 'state',
          },
        ],
        quickLinkItems: null,
        quickLinkItemsPerPage: 15,
        quickLinkItemsHeaders: [
          {
            text: 'Nom/Prénom',
            align: 'start',
            sortable: false,
            value: 'username',
          },
          { text: 'Email', value: 'email' },
          { text: 'Conseiller Infocep', value: 'conseiller' },
          { text: 'Code Conseiller Infocep', value: 'code_fc_conseiller' },
          { text: 'DM Conseiller', value: 'dm_conseiller' },
          { text: 'DM Code Conseiller', value: 'dm_id_conseiller' },
          { text: 'Etat', value: 'state' },
          
        ],
        quickLinkSearch: null,
        syncLoading: false,
        isDisplayCriteria: false,
        isDisplayParcours: false,
        confirmDeleteUser: false,
        confirmUserEmail: null,
        confirmUserEmailError: false,
        userIsDeleted: false,
        deletedUserDataList: [],
        deleteUserSieError: false,
      }
    },
    computed: {
      numberOfPages() {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys() {
        return this.keys.filter(key => key !== 'Email')
      },
    },
    filters: {
      timelineColor(key, item) {
        switch(key) {
          case 'Datamart':
            if (item.date_del) {
              return 'red';
            }
            if (item.id_conseiller === 0) {
              return 'orange';
            }
            return (item.isExists) ? 'green' : 'red';
          case 'Smart Profile':
            if (item.smartp.length > 0) {
              if (item.smartp[0].open) {
                return 'green';
              } else if(item.smartp[0].sent) {
                return 'orange';
              }
            }
            return 'red';
          case 'Infocep.fr':
            if (!item.infocep.id) {
              return 'red';
            } else if (!item.isConseiller && (!item.infocep.conseiller || item.id_conseiller !== parseInt(item.infocep.code_fc_conseiller))) {
              return 'orange';
            }
            return (item.infocep.role) ? 'green' : 'red';
          default:
            return 'green';
        }
      },
      formatDate(date, style) {
        console.log(date, style);
        if (date && date.indexOf('0000-00-00') === -1) {
          if (style === 'date') {
            return dayjs(date).format('DD/MM/YYYY');  
          }
          return dayjs(date).format('DD/MM/YYYY [à] HH[h]mm');
        }
        return '-';
      },
      displayRole(item) {
        if (item.isConseiller) {
          return 'conseiller';
        } else if (item.isCDI) {
          return 'CDI';
        }
        return '-';
      },
      idRoleToString(role) {
        switch (role) {
          case 1: 
            return 'bénéféciaire';
          case 2: 
            return 'bénéféciaire';
          case 3: 
            return 'conseiller';
          case 4: 
            return 'CDI';
        }
      }
    },
    watch:{
      $route() {
        this.items = [];
        this.search = '';
      },
    },
    methods: {
      async onSearch() {
        const apiMethods = (this.$route.name === 'Conseillers') ? 'conseillers' : 'users';
        if (this.search.length > 5) {
          this.onClear();
          this.loading = true;
          const search = this.search.replace(/ /g,',');
          try {
            const users = await this.axios.post(`/dashboards/${apiMethods}`, { emails: search });
            this.loading = false;
            this.items = users.data;
          } catch (e) {
            this.loading = false;
            console.log(e);
          }
        }
      },
      onClear() {
        this.items = [];
        this.lastExpandedItem = null;
      },
      onClearUpdateUser(item) {
        let itemsCopy = this.setItemEvent(item, 'onEditEmail', false);
        this.items = itemsCopy;
      },
      onEditEmail(item) {
        let itemsCopy = this.setItemEvent(item, 'onEditEmail', true);
        this.items = itemsCopy;
      },
      async onUpdateUser(item, isUpdateRole) {
        // let itemsCopy = this.setItemEvent(item, 'onUpdateUser', true);
        // itemsCopy = this.setItemEvent(item, 'onUpdatedUser', true);
        // this.items = itemsCopy;

        if (isUpdateRole) {
          item.role = this.getUserRole(item);
        }
        try {
          // const updateUser =
          await this.axios.put('/dashboards/update-user', { user: item });
        } catch (e) {
          console.log(e);
        }
        // itemsCopy = this.setItemEvent(item, 'onEditEmail', false);
        // itemsCopy = this.setItemEvent(item, 'onUpdateUser', false);
        // itemsCopy = this.setItemEvent(item, 'onUpdatedUser', true);
        // this.items = itemsCopy;
      },
      async onSendOnboarding(item) {
        let itemsCopy = this.setItemEvent(item, 'onSend', true);
        this.items = itemsCopy;
        try {
          const onboarding = await this.axios.post('/dashboards/send-onboarding-message', { user: item });
          if (onboarding.data && onboarding.data.response  === 'ok') {
            const itemsCopy = this.setItemEvent(item, 'onboardingSent', true);
            this.items = itemsCopy;
          }
        } catch (e) {
          console.log(e);
        }
      },
      onDelete(item) {
        let itemsCopy = this.setItemEvent(item, 'onDelete', true);
        this.items = itemsCopy;
        setTimeout(() => {
          itemsCopy = this.setItemEvent(item, 'onDelete', false);
          this.items = itemsCopy;
        }, 1000);
      },
      async onUpdateConseiller(item) {
        const itemsCopy = this.setItemEvent(item, 'onUpdateConseiller', true);
        this.items = itemsCopy;

        try {
          const onUpdateConseiller = await this.axios.put('/dashboards/update-conseiller', { user: item });
          const key = _.findKey(this.items, { 'email': item.email });
          const itemsCopyBenef = _.cloneDeep(this.items);

          if(!itemsCopyBenef[key].infocep.conseiller) {
            itemsCopyBenef[key].infocep.conseiller = {};
          }

          if(item.id_conseiller === 0) {
            itemsCopyBenef[key].infocep.code_fc_conseiller = 0;
            itemsCopyBenef[key].infocep.conseiller.username = '-';
            this.items = itemsCopyBenef;
        
            const itemsCopy = this.setItemEvent(item, 'onUpdatedConseiller', true);
            this.items = itemsCopy;
          } else if (onUpdateConseiller.data && onUpdateConseiller.data.status) {
            itemsCopyBenef[key].infocep.code_fc_conseiller = item.id_conseiller;
            itemsCopyBenef[key].infocep.conseiller.username = item.conseiller;
            this.items = itemsCopyBenef;
        
            const itemsCopy = this.setItemEvent(item, 'onUpdatedConseiller', true);
            this.items = itemsCopy;
          } else {
            itemsCopyBenef[key].infocep.code_fc_conseiller = item.id_conseiller;
            itemsCopyBenef[key].infocep.conseiller.username = '-';
            this.items = itemsCopyBenef;

            const itemsCopy = this.setItemEvent(item, 'onUpdateConseiller', false);
            this.items = itemsCopy;

            this.alertMessage = 'L\'identifiant du conseiller a bien été ajouté au bénéficiaire, mais le conseiller n\'est pas inscrit sur Infocep.fr'
          }
        } catch (e) {
          console.log(e);
        }
      },
      setItemEvent(item, event, state) {
        const key = _.findKey(this.items, { 'email': item.email });
        if (key) {
          const itemsCopy = _.cloneDeep(this.items);
          itemsCopy[key][event] = state;
          return itemsCopy;
        }
      },
      onExpand(item) {
        if (this.lastExpandedItem) {
          const lastItemsCopy = this.setItemEvent(this.lastExpandedItem, 'isExpanded', false);  
          this.items = lastItemsCopy;
        }
        const itemsCopy = this.setItemEvent(item, 'isExpanded', !item.isExpanded);
        this.items = itemsCopy;
        this.lastExpandedItem = item;
      },
      setSubtitle(item) {
        if (!item.isExists) {
          return {
            color: 'red',
            icon: 'mdi-alert-outline',
            info: 'Cette utilisateur n\'existe pas dans SIE',
            text: (item.infocep && item.infocep.id) ? 'Utilisateur inscrit sur Infocep.fr' : 'Utilisateur non-inscrit sur Infocep.fr',
          };
        } else if (item.date_del) {
          return {
            color: 'red',
            icon: 'mdi-alert-outline',
            info: 'Le bénéficiaire a été supprimé de SIE',
            text: `Inscrit sur Infocep.fr le ${this.$options.filters.formatDate(item.infocep.createdAt)}`,
          };
        } else if (item.infocep) {
          if (item.id_conseiller === 0) {
            return {
              color: 'orange',
              icon: 'mdi-alert-circle-outline',
              info: 'Aucun conseiller défini dans SIE',
              text: `Inscrit sur Infocep.fr le ${this.$options.filters.formatDate(item.infocep.createdAt)}`,
            };
          } else if(!item.infocep.id) {
            return {
              color: 'red',
              icon: 'mdi-alert-outline',
              info: '-',
              text: 'Utilisateur non-inscrit sur Infocep.fr',
            };
          } else if (!item.isConseiller && item.id_conseiller && !item.infocep.conseiller) {
            return {
              color: 'orange',
              icon: 'mdi-alert-circle-outline',
              info: 'Le conseiller SIE n\'est pas associé au bénéficiaire Infocep.fr',
              text: `Inscrit sur Infocep.fr le ${this.$options.filters.formatDate(item.infocep.createdAt)}`,
            }
          } else if (!item.isConseiller && item.id_conseiller !== parseInt(item.infocep.code_fc_conseiller)) {
            return {
              color: 'orange',
              icon: 'mdi-alert-circle-outline',
              info: 'Les conseillers ne sont pas identiques entre SIE et Infocep.fr',
              text: `Inscrit sur Infocep.fr le ${this.$options.filters.formatDate(item.infocep.createdAt)}`,
            }
          } else if(item.infocep.createdAt) {
            return {
              color: 'green',
              icon: 'mdi-check-circle-outline',
              info: 'all good !',
              text: `Inscrit sur Infocep.fr le ${this.$options.filters.formatDate(item.infocep.createdAt)}`,
            }
          }
        }
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
      async onQuickLink() {
        this.loading = true;
        this.notFound = null;
        this.quickLinkItems = [];
        try {
          const users = await this.axios.get('/dashboards/users-sync-conseillers?sync=1');
          this.loading = false;
          if (users.data.length > 0) {
            this.quickLinkItems = users.data;
          } else {
            this.notFound = 'Tous les comptes sont synchronisés';
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      },
      onUpdateConseillerByBatch() {
        this.syncLoading = true;
        this.quickLinkItemsPerPage = -1;
        eachOfSeries(this.quickLinkItems, async (item, index, cb) => {
          const user = {
            email: item.email,
            id_conseiller: item.dm_id_conseiller,
            id_benef: item.id_benef,
            infocep: {
              id: item.id,
              conseiller: {
                id_conseiller: (item.conseiller) ? item.conseiller.id : null,
              },
            },
          };
          try {
            await this.axios.put('/dashboards/update-conseiller', { user });
            this.quickLinkItems[index].state = 1;
            cb();
          } catch(e) {
            console.log(e);
          }
        }, () => {
          this.syncLoading = false;
        });
      },
      dateDiff(date) {
        if (date && date.indexOf('0000-00-00') === -1) {
          return dayjs().diff(date, 'week');  
        }
        return 0;
      },
      hasEqualRight(item) {
        if (item.isConseiller) {
          return item.infocep.role.id === 3
        }
        if (item.isCDI) {
          return item.infocep.role.id === 4
        }
        return true;
      },
      getUserRole(item) {
        if (item.isConseiller) {
          return 3;
        }
        if (item.isCDI) {
          return 4;
        }
        return 2;
      },
      onDisplayCriteria() {
        this.isDisplayCriteria = !this.isDisplayCriteria;
        if (!this.isDisplayCriteria) {
          this.isDisplayParcours = false;
        }
      },
      onDisplayParcours() {
        this.isDisplayParcours = !this.isDisplayParcours;
      },
      getCriteresStatus(item) {
        if(item.smartp && item.smartp[0] && item.smartp[0].sent) {
          return {
            message: `Au ${this.$options.filters.formatDate(item.smartp[0].sent)} les critères étaient ok`,
            icon: {
              color: 'green',
              icon: 'mdi-check-circle-outline',
            }
          }
        } else {
          let hasError = false;
          let message = `Les critères suivant ne remplissent pas l'envoi de l'email d'onboarding :`;
          message += '<ul>';
          if(['UO_1', 'UO_2_1'].indexOf(item.rdv_uo) === -1) {
            hasError = true;
            message += '<li>Le rendez-vous n\'est pas de type UO_1 ou UO_2</li>';
          }
          if(item.date_finalisation !== null) {
            hasError = true;
            message += '<li>Le bénéficiaire n\'est plus suivi</li>';
          }
          message += '</ul>';
          if (hasError) {
            return {
              message,
              icon: {
                color: 'red',
                icon: 'mdi-alert-outline',
              },
            };
          }
          return {
            message: 'Les critères sont ok !',
            icon: {
              color: 'green',
              icon: 'mdi-check-circle-outline',
            }
          }
        }
      },
      onDeleteUser(item) {
        this.item = item;
        this.confirmDeleteUser = true;
      },
      async onConfirmDeleteUser() {
        if (this.item && this.item.email === this.confirmUserEmail) {
          try {
            const id = btoa(JSON.stringify({
              id: this.item.infocep.id,
              id_benef: this.item.id_benef,
              email: this.item.email,
            }));
            const sources = await this.axios.delete(`/dashboards/${id}`, { user: this.item });
            const dataList = [
              `Activités (${sources.data.activities})`,
              `Exercices (${sources.data.exercices})`,
              `Contenus recommandés (${sources.data['highlight-content']})`,
              `Rendez-vous (${sources.data.['rendez-vous']})`,
              `Messagerie (${sources.data.messages})`,
              'Compte',
            ];
            let index = 0;
            const intervalID = setInterval(() => {
              this.deletedUserDataList.push(dataList[index]);
              index += 1;
              if (this.deletedUserDataList.length === dataList.length) {
                clearInterval(intervalID);
                this.userIsDeleted = true;
              }
            }, 1000);
          } catch (e) {
            if (e && e.response) {
              this.deleteUserSieError = (e.response.data.message === 'User exists in SIE');
            }
            console.log(e);
          }
        } else {
          this.confirmUserEmailError = true;
        }
      },
      onCloseDeleteUserModal() {
        this.deletedUserDataList = [];
        this.userIsDeleted = false;
        this.confirmUserEmail = null;
        this.confirmDeleteUser = false;
        this.confirmUserEmailError = false;
        this.item = null;
        this.items = [];
        this.search = '';
      }
    }
  }
</script>

<style lang="scss" scoped>
.subheading {
  font-size: 1rem !important;
}
.title {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  text-align: left;
}
.subtitle {
  display: flex;
  text-align: left;
  align-items: center;
  padding-left: 5px !important;
}
.alert {
  position: fixed !important;
  z-index: 10;
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  // top: 0px;
  // transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.toolbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  .quickLink {
    width: 200px;
    padding-bottom: 25px;
    align-self: flex-end;
  }
}
.details-parcours {
  th, td {
    border: 1px solid black;
    padding: 5px;
  }
}
.cta-delete {
  .v-btn {
    color: white;
  }
}
.delete-user-data {
  min-height: 32px;
  height: 32px;
  .v-list-item__icon {
    margin-top: 2px;
  }
}
</style>