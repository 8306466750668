<template>
  <div class="pt-3">
    <!-- <v-alert
      v-if="alertMessage"
      dense
      dismissible
      type="warning"
      class="alert"
    >
      {{ alertMessage }}
    </v-alert> -->
    <v-row class="frame pb-3">
      <v-col
        class="title"
        cols="12"
      >
        Aujourd'hui
      </v-col>
      <v-col
        cols="4"
      >
        <div class="card">
          <div class="logo benef">
            <v-icon>
              mdi-account
            </v-icon>
          </div>
          <p>Bénéficiaires</p>
          <p class="number">+{{ daily.user }}</p>
        </div>
      </v-col>
       <v-col
        cols="4"
      >
        <div class="card">
          <div class="logo rdv">
            <v-icon>
              mdi-calendar
            </v-icon>
          </div>
          <p>Rendez-vous</p>
          <p class="number">+{{ daily.rdv }}</p>
        </div>
      </v-col>
      <v-col
        cols="4"
      >
        <div class="card">
          <div class="logo conseiller">
            <v-icon>
              mdi-account-details
            </v-icon>
          </div>
          <p>Conseillers</p>
          <p class="number">+{{ daily.conseiller }}</p>
        </div>
      </v-col>
    </v-row>
     <v-row class="frame pb-3">
      <v-col
        cols="12"
      >
        <p class="title" style="padding-bottom: 0; margin-block-end: 0;">Régions et périodes : </p>  
      </v-col>
      <v-col
        :cols="(!setCustomPeriod) ? 2 : 12">
        <v-combobox
            style="max-width: 200px;"
            v-model="region"
            :items="regions"
            outlined
            @change="onRegion()"
          ></v-combobox>
      </v-col>
      <template v-if="!setCustomPeriod">
        <v-col
          cols="10"
        >
          <v-combobox
            style="max-width: 200px;"
            v-model="period"
            :items="periods"
            outlined
            @change="onPeriod()"
          ></v-combobox>
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="4"
        >
          <v-menu
            ref="datePickerFrom"
            v-model="datePickerFrom"
            :close-on-content-click="false"
            :return-value.sync="from"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="from"
                label="Du"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="from"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="datePickerFrom = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.datePickerFrom.save(from)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="4"
        >
          <v-menu
            ref="datePickerTo"
            v-model="datePickerTo"
            :close-on-content-click="false"
            :return-value.sync="to"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="to"
                label="Au"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="to"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="datePickerTo = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.datePickerTo.save(to)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="4"
          style="display: flex; align-items: center;"
        >
          <v-btn @click="onCustomPeriod()" color="#1A3787" style="color: #FFF;">
            valider
          </v-btn>
          <v-btn @click="onCancelCustomPeriod()" color="#FEF8D4" style="margin-left: 10px; color: #000;">
            annuler
          </v-btn>
        </v-col>
      </template>
      <v-col
        cols="4"
      >
        <div class="card">
          <div class="logo benef">
            <v-icon>
              mdi-account
            </v-icon>
          </div>
          <p>Bénéficiaires</p>
          <p class="number">+{{ period.user }}</p>
        </div>
      </v-col>
       <v-col
        cols="4"
      >
        <div class="card">
          <div class="logo rdv">
            <v-icon>
              mdi-calendar
            </v-icon>
          </div>
          <p>Rendez-vous</p>
          <p class="number">+{{ period.rdv }}</p>
        </div>
      </v-col>
      <v-col
        cols="4"
      >
        <div class="card">
          <div class="logo conseiller">
            <v-icon>
              mdi-account-details
            </v-icon>
          </div>
          <p>Conseillers</p>
          <p class="number">+{{ period.conseiller }}</p>
        </div>
      </v-col>
      <v-col
        cols="6"
      >
        <div id="chart">
          <apexchart v-if="!chartLoading" type="area" height="350" :options="userChartOptions" :series="userSeries"></apexchart>
        </div>
      </v-col>
      <v-col
        cols="6"
      >
        <div id="chart">
          <apexchart v-if="!chartLoading" type="area" height="350" :options="conseillerChartOptions" :series="coneillerSeries"></apexchart>
        </div>
      </v-col>
      <v-col
        cols="12"
      >
        <div id="rdvChart">
          <apexchart v-if="!chartRdvLoading" type="area" height="350" :options="rdvChartOptions" :series="rdvSeries"></apexchart>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import _ from 'lodash';
  import * as dayjs from 'dayjs';
  export default {
    name: 'Dashboard',
    data () {
      return {
        chartLoading: true,
        chartRdvLoading: true,
        setCustomPeriod: false,
        daily: {
          user: 0,
          rdv: 0,
          conseiller: 0,
        },
        period: {
          text: 'Semaine en cours',
          user: 0,
          rdv: 0,
          conseiller: 0,
          value: {
            from: dayjs().day(1).format('YYYY-MM-DD'),
            to: dayjs().day(7).format('YYYY-MM-DD'),
          }
        },
        periods: [
          {
            text: 'Semaine en cours',
            value: {
              from: dayjs().day(1).format('YYYY-MM-DD'),
              to: dayjs().day(7).format('YYYY-MM-DD'),
            }
          },
          {
            text: 'Mois en cours',
            value: {
              from: dayjs().startOf('M').format('YYYY-MM-DD'),
              to: dayjs().endOf('M').format('YYYY-MM-DD'),
            }
          },
          {
            text: 'Semaine dernière',
            value: {
              from: dayjs().subtract(1, 'week').day(1).format('YYYY-MM-DD'),
              to: dayjs().subtract(1, 'week').day(7).format('YYYY-MM-DD'),
            }
          },
          {
            text: 'Mois dernier',
            value: {
              from: dayjs().subtract(1, 'month').startOf('M').format('YYYY-MM-DD'),
              to: dayjs().subtract(1, 'month').endOf('M').format('YYYY-MM-DD'),
            }
          },
          {
            text: 'Personaliser',
            value: 'custom'
          },
        ],
        region: {
          text: 'Toutes les régions',
          value: 'all'
        },
        regions: [
          {
            text: 'Bretagne',
            value: 'bretagne'
          },
          {
            text: 'Centre-Val de Loire',
            value: 'centre-val-de-loire'
          },
          {
            text: 'Hauts de france',
            value: 'hauts-de-france'
          },
          {
            text: 'Ile de France',
            value: 'île-de-france'
          },
          {
            text: 'Normandie',
            value: 'normandie'
          },
          {
            text: 'Pays de la Loire',
            value: 'pays-de-la-loire'
          },
          {
            text: 'Toutes les régions',
            value: 'all'
          },
        ],
        from: new Date().toISOString().substr(0, 10),
        to: new Date().toISOString().substr(0, 10),
        datePickerFrom: false,
        datePickerTo: false,
        userSeries: [],
        coneillerSeries: [],
        rdvSeries: [],
        userChartOptions: {
          chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
              zoomedArea: {
                fill: {
                  color: '#FEF8D4',
                  opacity: 0.4
                },
                stroke: {
                  color: '#1A3787',
                  opacity: 0.4,
                  width: 2
                }
              }
            },
            toolbar: {
              tools: {
                zoom: true,
                download: false,
                pan: false,
                reset: `<img src="${require('@/assets/refresh.png')}"/>`,
                customIcons: [
                  {
                    icon: `<img src="${require('@/assets/file-document-outline.png')}" width="20"/>`,
                    class: 'custom-icon',
                    title: 'Exporter au format CSV',
                    click: () => {
                      this.onExportCSV('beneficiaires');
                    },
                  },
                ],
              },
            },
          },
          colors: ['#1A3787'],
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Inscription des bénéficiaires',
            align: 'left'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.5,
              opacityTo: 0,
              stops: [0, 90, 100]
            },
          },
          yaxis: {
            title: {
              text: 'Nombre'
            },
          },
          xaxis: {
            labels: {
              formatter: (val) => {
                return `${dayjs(val).format('DD-MMM')}`;
              },
            },
            tickAmount: 14,
          },
          tooltip: {
            shared: false,
          }
        },
        conseillerChartOptions: {
          chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
              zoomedArea: {
                fill: {
                  color: '#fef8d4',
                  opacity: 0.4
                },
                stroke: {
                  color: '#fef8d4',
                  opacity: 0.4,
                  width: 2
                }
              }
            },
            toolbar: {
              tools: {
                zoom: true,
                download: false,
                pan: false,
                reset: `<img src="${require('@/assets/refresh.png')}"/>`,
                customIcons: [
                  {
                    icon: `<img src="${require('@/assets/file-document-outline.png')}" width="20"/>`,
                    class: 'custom-icon',
                    title: 'Exporter au format CSV',
                    click: () => {
                      this.onExportCSV('conseillers');
                    },
                  },
                ],
              },
            },
          },
          colors: ['#fef8d4'],
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Inscription des conseillers',
            align: 'left'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.5,
              opacityTo: 0,
              stops: [0, 90, 100]
            },
          },
          yaxis: {
            title: {
              text: 'Nombre'
            },
          },
          xaxis: {
            labels: {
              formatter: (val) => {
                return `${dayjs(val).format('DD-MMM')}`;
              },
            },
            tickAmount: 14,
          },
          tooltip: {
            shared: false,
          }
        },
        rdvChartOptions: {
          chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
              zoomedArea: {
                fill: {
                  color: '#FEF8D4',
                  opacity: 0.4
                },
                stroke: {
                  color: '#D5EDE9',
                  opacity: 0.4,
                  width: 2
                }
              }
            },
            toolbar: {
              tools: {
                zoom: true,
                download: false,
                pan: false,
                reset: `<img src="${require('@/assets/refresh.png')}"/>`,
                customIcons: [
                  {
                    icon: `<img src="${require('@/assets/file-document-outline.png')}" width="20"/>`,
                    class: 'custom-icon',
                    title: 'Exporter au format CSV',
                    click: () => {
                      this.onExportCSV('rendez-vous');
                    },
                  },
                ],
              },
            }
          },
          colors: ['#D5EDE9'],
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Prise de rendez-vous',
            align: 'left'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.5,
              opacityTo: 0,
              stops: [0, 90, 100]
            },
          },
          yaxis: {
            title: {
              text: 'Nombre'
            },
          },
          xaxis: {
            labels: {
              formatter: (val) => {
                return `${dayjs(val).format('DD-MMM')}`;
              },
            },
            tickAmount: 14,
          },
          tooltip: {
            shared: false,
          }
        },
      }
    },
    mounted() {
      this.getStats('user', '1,2', this.period.value.from, this.period.value.to, this.region.value);
      this.getStats('user', '3,4', this.period.value.from, this.period.value.to, this.region.value);
      this.getStats('rdv', null, this.period.value.from, this.period.value.to, this.region.value);
      this.lastPeriod = this.period;
      this.getDailyStats();
    },
    methods: {
      onPeriod() {
        if (this.period.value === 'custom') {
          this.setCustomPeriod = true;
        } else {
          this.lastPeriod = this.period;
          this.getStats('user', '1,2', this.period.value.from, this.period.value.to, this.region.value);
          this.getStats('user', '3,4', this.period.value.from, this.period.value.to, this.region.value);
          this.getStats('rdv', null, this.period.value.from, this.period.value.to, this.region.value);
        }
      },
      onCustomPeriod() {
        this.getStats('user', '1,2', this.from, this.to, this.region.value);
        this.getStats('user', '3,4', this.from, this.to, this.region.value);
        this.getStats('rdv', null, this.from, this.to, this.region.value);
      },
      onCancelCustomPeriod() {
        this.setCustomPeriod = false;
        this.period = this.lastPeriod;
        this.getStats('user', '1,2', this.period.value.from, this.period.value.to, this.region.value);
        this.getStats('user', '3,4', this.period.value.from, this.period.value.to, this.region.value);
        this.getStats('rdv', null, this.period.value.from, this.period.value.to, this.region.value);
      },
      onRegion() {
        const from = this.period.value.from || this.from;
        const to = this.period.value.to || this.to;
        this.getStats('user', '1,2', from, to, this.region.value);
        this.getStats('user', '3,4', from, to, this.region.value);
        this.getStats('rdv', null, from, to, this.region.value);
      },
      async getDailyStats() {
        try {
          const users = await this.axios.get(`/dashboards/daily-stats?source=user&role=1,2`);
          if (users.data.length > 0) {
            this.daily.user = users.data[0].value;
          }
        } catch (e) {
          console.log(e);
        }
        try {
          const conseiller = await this.axios.get(`/dashboards/daily-stats?source=user&role=3,4`);
          if (conseiller.data.length > 0) {
            this.daily.conseiller = conseiller.data[0].value;
          }
        } catch (e) {
          console.log(e);
        }
        try {
          const rdv = await this.axios.get(`/dashboards/daily-stats?source=rdv`);
          if (rdv.data.length > 0) {
            this.daily.rdv = rdv.data[0].value;
          }
        } catch (e) {
          console.log(e);
        }
      },
      async getStats(source, role, from, to, region) {
        try {
          const users = await this.axios.get(`/dashboards/users-stats?source=${source}&from=${from}&to=${to}&role=${role}&region=${region}`);
          if(source === 'user') {
            if (role === '1,2') {
              this.period.user = _.sumBy(users.data, (data) => parseInt(data.y, 10));
              this.userSeries = [{
                name: 'Bénéficiaire',
                data: users.data,
              }];
            } else {
              this.period.conseiller = _.sumBy(users.data, (data) => parseInt(data.y, 10));
              this.coneillerSeries = [{
                name: 'Conseillers',
                data: users.data,
              }];
            }
            this.chartLoading = false;
          } else {
            this.period.rdv = _.sumBy(users.data, (data) => parseInt(data.y, 10));
            this.rdvSeries = [{
              name: 'Rendez-vous',
              data: users.data,
            }];
            this.chartRdvLoading = false;
          }
         
        } catch (e) {
          this.chartLoading = false;
          this.chartRdvLoading = false;
          console.log(e);
        }
      },
      async onExportCSV(type) {
        const from = this.period.value.from || this.from;
        const to = this.period.value.to || this.to;
        try {
          let filename = '';
          if (type === 'rendez-vous') {
            filename = `${dayjs(from).format('YYYYMMDD')}_EXPORT_PRISE_RDV_INFOCEP.csv`;
          } else if(type === 'beneficiaires') {
            filename = `${dayjs(from).format('YYYYMMDD')}_EXPORT_BENEFICIAIRES_INFOCEP.csv`;
          } else {
            filename = `${dayjs(from).format('YYYYMMDD')}_EXPORT_CONSEILLERS_INFOCEP.csv`;
          }
          this.axios({
            url: `/${type}/rapport?from=${from}&to=${to}&region=${this.region.value}`,
            method: 'GET',
            responseType: 'blob',
          }).then((rdv) => {
            const url = window.URL.createObjectURL(new Blob([rdv.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          });
        } catch (e) {
          console.log(e);
        }
      },
    }
  }
</script>

<style lang="scss">
.frame {
  .title {
    text-align: left;
    padding-bottom: 15px;
    text-decoration: underline;
  }
  .card {
    text-align: right;
    box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
    padding: 10px;
    position: relative;
    p {
      margin-block-start: 0;
      margin-block-end: 0;
      color: grey;
    }
    .benef {
      background-color: #1A3787;
    }
    .rdv {
      background-color: #D5EDE9;
    }
    .conseiller {
      background-color: #FEF8D4;
       .v-icon {
        color: grey !important;
      }
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      margin-top: 5px;
      width: 50px;
      height: 50px;
      border-radius: 15px;
      .v-icon {
        color: white;
        font-size: 28px
      }
    }
    .number {
      font-weight: bold;
      color: #000;
      font-size: 24px;
    }
  }
}
.custom-icon {
  padding-top: 2px;
  padding-left: 5px;
}
.apexcharts-toolbar {
  z-index: 0;
}
</style>